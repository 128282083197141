import { useState } from "react";
import React from "react";
import { register } from "../actions/user/action";
import { connect } from "react-redux";
// import Head from "./Head";

const Register = ({ register, userId }) => {
  const [email, updateEmail] = useState("");
  const [password, updatePassword] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const registerUser = () => {
    if (email && password) {
      register({ email, password, firstName, lastName });
    }
  };
  return (
    <>
      {/* <Head /> */}
      <div>
        {userId ? <p>logged in: {userId}</p> : null} <br />
        <label>email</label>
        <input onChange={(e) => updateEmail(e.target.value)} />
        <label>password</label>
        <input onChange={(e) => updatePassword(e.target.value)} />
        <label>First name</label>
        <input onChange={(e) => setfirstName(e.target.value)} />
        <label>last name</label>
        <input onChange={(e) => setlastName(e.target.value)} />
        <button onClick={() => registerUser()}>Register</button>
      </div>
    </>
  );
};

const mapStateToProps = ({ data }) => ({
  userId: data.userId,
});

export default connect(mapStateToProps, { register })(Register);
